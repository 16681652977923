import React from 'react';

import Wrapper          from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import HeaderMedia      from '@interness/theme-default/src/components/HeaderMedia';
import Heading          from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer           from '@interness/web-core/src/components/structure/Spacer/Spacer';
import ResponsiveIFrame from '@interness/web-core/src/components/structure/iFrame/iFrame';

function IndexPage(props) {
  return (
    <>
      <HeaderMedia id={'index-6'}/>
      <Wrapper>
        <Spacer/>
        <Heading subtitle="Herzlich Willkommen">Bandweberei Sattler</Heading>
        <Spacer height={20}/>
        <ResponsiveIFrame src={'https://www.youtube-nocookie.com/embed/PFLg1bNTX5I?rel=0'}
                          title={'Bandweberei Sattler'}/>
        <Spacer height={20}/>
        <p>
          Die Bandweberei Sattler, im Herzen der schönen Kreisstadt Schwelm gelegen, ist seit 1906 national und
          international ein Begriff für erstklassige gewebte Bänder.
        </p>
        <p>
          Seit über 110 Jahren beschäftigen wir uns mit der Herstellung von technischen und textilen Bändern.
        </p>
        <p>Was 1906 im Kleinen begann, hat sich heute zu einem leistungsfähigen Unternehmen im Bereich gewebter Bänder
          und Bandkonfektionierung entwickelt.</p>

        <p>Während mit Gründung durch Friedrich Sattler, zur damaligen Produktpalette Leinen- und Baumwollbänder
          gehörten, hat sich das heutige Angebot im Bereich der technischen und textilen Bänder, deutlich erweitert.</p>

        <p>1965 übernahm der Enkel des Firmengründers, Friedel Sattler, den Betrieb um ihn bis heute weiterzuführen und
          auszubauen.</p>

        <p>2005 expandierte das Unternehmen durch den Zusammenschluss mit der Bandweberei Jürgen Sattler.</p>

        <p>Mit über 100 Bandwebmaschinen liegt der Bandausstoß heute bei nahezu 2 Millionen Meter je Woche.
          Kontinuierlich Verbesserungen und höchste Qualität stehen im Mittelpunkt unserer Produktion.
          Auf diese Weise entwickelte sich aus einem Kleinbetrieb, der in einem Hinterhof seinen Anfang nahm, ein
          modernes Unternehmen, dass nunmehr seit fast 120 Jahren besteht.</p>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;
